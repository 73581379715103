import { ArticleImage } from '@/@types/graphql-types';
import { ThumborImage } from '@/filters/thumbor';
import { AssertByTypename } from '@/helpers/graphql-model-helper';
import pictureCompHelper from '@/helpers/picture-comp-helper';

import { ArticleFragment } from '@/pages/graphql/queries/GetArticleByUrl.query';

export type Node = ArticleFragment['mainObjects'][number]['node'];
export type ArticleObject = AssertByTypename<Node, 'GenericTitleList' | 'Movie' | 'Show' | 'Season'>;

const getArticleBackdrop = (
	mainObject: ArticleObject | null,
	profile: 'backdrop' | 'article_backdrop' = 'article_backdrop'
) => {
	if (!mainObject) return null;

	const backdropUrl =
		mainObject.__typename === 'GenericTitleList'
			? (mainObject.titles.edges ?? [])[0]?.node.content.backdrops[0]?.backdropUrl
			: mainObject.content.backdrops[0]?.backdropUrl;

	if (!backdropUrl) return null;

	return pictureCompHelper.getDefaultImageUrl(backdropUrl, profile, '', false);
};

const getLocalizedArticleImage = (
	images: ArticleImage[],
	imageType: keyof ThumborImage<any>,
	language: string
): string | null => {
	let localizedImage: ArticleImage | undefined;
	let globalImage: ArticleImage | undefined;
	images.forEach(image => {
		if (image.imageType !== imageType) {
			return;
		}

		if (image.language === language) {
			localizedImage = image;
		} else if (image.language === '') {
			globalImage = image;
		}
	});

	if (localizedImage) {
		return pictureCompHelper.getDefaultImageUrl(localizedImage.url, imageType, '', false);
	}

	if (globalImage) {
		return pictureCompHelper.getDefaultImageUrl(globalImage.url, imageType, '', false);
	}

	return null;
};

export { getArticleBackdrop, getLocalizedArticleImage };
